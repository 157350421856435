<template>
  <div class="container" :style="height">
    <div class="title">修改个人信息</div>
    <div class="exithead mt20">
      <dl>
        <dt>用户名：</dt>
        <dd>
          <el-input v-model="username" placeholder="用户名"></el-input>
          <el-button type="primary" class="mt20" @click="exitClick">确认修改</el-button>
        </dd>
      </dl>
      <!-- <dl>
        <dt>头像：</dt>
        <dd>
          <el-upload
            class="avatar-uploader"
            :action="ImgApiOne"
            :show-file-list="false"
            :on-success="handleAvatarSuccess"
            name="file"
            :data="{folder:'file'}"
          >
            <img v-if="path" :src="path" class="avatar" />
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          </el-upload>
          <el-button type="primary" class="mt20" @click="exitClick">确认修改</el-button>
        </dd>
      </dl>-->
    </div>
  </div>
</template>
<style>
.exithead dl {
  display: table;
  width: 100%;
  margin-bottom: 10px;
}
.exithead dl dt {
  float: left;
  font-size: 15px;
  margin-right: 20px;
  width: 65px;
  line-height: 40px;
}
.exithead dl dd {
  float: left;
}
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}

.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 120px;
  height: 120px;
  line-height: 120px;
  text-align: center;
}

.avatar {
  width: 120px;
  height: 120px;
  display: block;
}
</style>
<script>
// updateUrl,
import { userselectOne, updateUsername } from "@/assets/js/api";
export default {
  name: "exithead",
  data() {
    return {
      ImgApiOne: "/jeefast-rest/user/user/api/imgupload",
      path: "",
      imgBasUrl: this.imgBasUrl,
      height: { height: "" },
      checked: true,
      username: ""
    };
  },
  methods: {
    // 可视区域高度
    getHight() {
      this.height.height = window.innerHeight - 180 + "px";
    },
    handleAvatarSuccess(res) {
      this.path = res.path;
    },
    exitClick() {
      // updateUrl({
      //   url: this.path
      // }).then(result => {
      //   if (result.code == 0) {
      //     this.$message({
      //       message: "个人信息修改成功",
      //       type: "success"
      //     });
      //     this.userselectOne();
      //     this.$router.go(0);
      //   } else {
      //     this.$message.error(result.msg);
      //   }
      // });
      this.updateUsername();
    },
    userselectOne() {
      userselectOne({}).then(result => {
        if (result.code == 0) {
          this.path = result.user.url;
          this.username = result.user.username;
        }
      });
    },
    updateUsername() {
      updateUsername({
        username: this.username
      }).then(result => {
        if (result.code == 0) {
          this.$message.success("修改成功");
        }
      });
    }
  },
  created() {
    this.getHight();
    window.addEventListener("resize", this.getHight);
  },
  destroyed() {
    window.removeEventListener("resize", this.getHight);
  },
  mounted() {
    this.userselectOne();
  }
};
</script>
